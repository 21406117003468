import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      storeList: [],
      generateCompanyList: [],
      jwnetItemsOpt: [],
      disposalCompanyList: [],
      cbaItems: [],
    };
  },
  methods: {
    // 運搬に紐づく排出事業者選択肢取得API
    getGeneratorApi() {
      return axios
        .get(API_URL.COLLECTOR.GENERATE_SELECT_COMPANY)
        .then((res) => {
          this.generateCompanyList = res.data.generateCompanyList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 事業場選択肢取得API
    getStoreListApi() {
      return axios
        .get(API_URL.SCHEDULE.GENERATE_STORE)
        .then((res) => {
          this.storeList = res.data.generateCompanyList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 運搬に紐づく処分事業者選択肢取得API
    getGeneratorDisposalApi() {
      return axios
        .get(API_URL.COLLECTOR.DISPOSAL_LIST)
        .then((res) => {
          this.disposalCompanyList = res.data.disposalCompanyList;
        })
        .catch(() => {});
    },

    // 廃棄物種類選択肢取得API    API_URL.DOMAIN.JWNET_ITEM_TYPE
    getJwnetItemsApi() {
      axios
        .get(API_URL.DOMAIN.JWNET_ITEM_TYPE)
        .then((res) => {
          this.jwnetItemsOpt = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // CBA品目選択肢取得API   MANIFEST.CBA_ITEMS
    getCbaItemsApi() {
      return axios
        .get(API_URL.MANIFEST.CBA_ITEMS)
        .then((res) => {
          this.cbaItems = res.data.cbaItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
