<template>
  <div>
    <div class="c-searchMenu__wrap">
      <div v-if="isDisplaySearch" class="c-searchMenu">
        <div class="l-flex">
          <div class="title icon">{{ isNoDateShow ? "引き渡し日未入力" : "引き渡し日入力済み" }}</div>
          <div v-if="shorten().length === 0">
            <div class="title">条件なし</div>
          </div>
          <template v-else v-for="(s, index) in shorten()">
            <div class="title" :key="'shorten-' + index">
              {{ s.key }}<div class="data"> : {{ s.value }}</div>
            </div>
          </template>
        </div>
        <div class="l-flex">
          <div class="c-btn secondary sm" @click="scrollTop">検索条件を変更</div>
        </div>
      </div>
    </div>
    <div class="l-full-white l-area">
      <div class="l-box l-flex">
        <h3 class="c-lead-s-black icon">検索</h3>
        <div v-if="!isNoDateShow" class="c-tabs l-area-l">
          <div
            class="c-tabs__each"
            :class="{
              active: !isSearchDetail,
            }"
            @click="isSearchDetail = false; initSearch(false);"
          >
            簡易
          </div>
          <div
            class="c-tabs__each"
            :class="{
              active: isSearchDetail,
            }"
            @click="isSearchDetail = true; initSearch(false);"
          >
            詳細
          </div>
        </div>
      </div>
      <div>
        <div v-if="!isNoDateShow" class="c-inputWrap">
          <div class="c-inputWrap__items__cols search-wrap">
            <div>
              <span class="text-label">廃棄物名称</span>
              <InputText
                :value.sync="searchParam.wasteName"
                inputType="text"
                placeholder="入力してください"
                :validation="validateMaxLen"
                :validateArg="100"
              >
              </InputText>
            </div>
            <div>
              <span class="text-label">排出事業者</span>
              <Selectbox
                class="mid"
                :value.sync="searchParam.generateCompanyId"
                :validation="validateJustBlank"
              >
                <option value="">すべて</option>
                <option
                  v-for="(opt, index) in generateCompanyList"
                  :key="`gc-opt-${index}`"
                  :value="opt.id"
                >
                  {{ opt.name }}
                </option>
              </Selectbox>
            </div>
            <div>
              <span class="text-label">マニフェスト番号</span>
              <InputText
                :value.sync="searchParam.manifestNo"
                inputType="text"
                placeholder="入力してください"
                :validation="validateNumber"
              >
              </InputText>
            </div>
            <div>
              <span class="text-label">引き渡し日</span>
              <div class="c-inputWrap__items__cols search-wrap">
                <InputDate
                  :value.sync="searchParam.collectionDateFrom"
                  placeholder="選択してください"
                />
                <span>〜</span>
                <InputDate
                  :value.sync="searchParam.collectionDateTo"
                  placeholder="選択してください"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isNoDateShow" class="c-inputWrap">
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">CBA品目</span>
                <Selectbox
                  class="mid"
                  :value.sync="searchParam.cbaItemId"
                  :validation="validateJustBlank"
                >
                  <option value="">すべて</option>
                  <option
                    v-for="(opt, index) in cbaItems"
                    :key="`cba-opt-${index}`"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
              <div>
                <span class="text-label">廃棄物種類</span>
                <Selectbox
                  class="mid"
                  :value.sync="searchParam.jwnetItemTypeId"
                  :validation="validateJustBlank"
                >
                  <option value="">すべて</option>
                  <option
                    v-for="(opt, index) in jwnetItemsOpt"
                    :key="`jwnet-opt-${index}`"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
              <div>
                <span class="text-label">ルート番号</span>
                <InputText
                  :value.sync="searchParam.routeId"
                  inputType="number"
                  placeholder="ルート番号を入力"
                  :validation="validateNumberMaxLen"
                  :validateArg="11"
                >
                </InputText>
              </div>
              <div>
                <span class="text-label">ルート名称</span>
                <InputText
                  :value.sync="searchParam.routeName"
                  inputType="text"
                  placeholder="ルート名称を入力"
                  :validation="validateMaxLen"
                  :validateArg="50"
                >
                </InputText>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isNoDateShow" class="c-inputWrap">
          <div class="c-inputWrap__items__cols search-wrap">
            <div>
              <span class="text-label">ステータス</span>
              <div class="l-flex wrap full">
                <div
                  v-for="(cb, index) in statusCb"
                  :key="index"
                  class="c-input"
                >
                  <div class="c-checkbox">
                    <input
                      :id="`cb-is-status-${index}`"
                      type="checkbox"
                      v-model="searchParam.status"
                      :value="cb.value"
                    />
                    <label
                      class="c-checkbox__label"
                      :for="`cb-is-status-${index}`"
                    >
                      <span class="c-checkbox__box"></span>
                      {{ cb.label }}
                      <span v-if="showcount(cb.value) !== null">
                        {{ `(${showcount(cb.value)})` }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 引き渡し日未設定の時だけ表示 -->
        <template v-else>
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">排出事業者</span>
                <Selectbox
                  class="mid"
                  :value.sync="searchParam.generateCompanyId"
                  :validation="validateJustBlank"
                >
                  <option value="">すべて</option>
                  <option
                    v-for="(opt, optIndex) in generateCompanyList"
                    :key="`gc-opt-${optIndex}`"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
              <div>
                <span class="text-label">マニフェスト番号</span>
                <InputText
                  :value.sync="searchParam.manifestNo"
                  inputType="text"
                  placeholder="入力してください"
                  :validation="validateNumber"
                >
                </InputText>
              </div>
            </div>
          </div>
        </template>

        <!-- 詳細のときだけ表示 -->
        <template v-if="isSearchDetail && !isNoDateShow">
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">交付日</span>
                <div class="c-inputWrap__items__cols search-wrap">
                  <InputDate
                    :value.sync="searchParam.issueDateFrom"
                    placeholder="選択してください"
                  />
                  <span>〜</span>
                  <InputDate
                    :value.sync="searchParam.issueDateTo"
                    placeholder="選択してください"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div v-for="(gs, index) in searchParam.generateStoreIdList" :key="`gs-${index}`">
                <span class="text-label">
                  <p v-if="index === 0">排出事業場</p>
                </span>
                <div class="l-area l-flex">
                  <Selectbox
                      class="mid"
                      :value.sync="gs.value"
                      :validation="validateJustBlank"
                  >
                    <option value="" v-if="searchParam.generateStoreIdList.length === 1">すべて</option>
                    <option
                        v-for="(opt, optIndex) in storeList.flatMap(c => c.generateStoreList)"
                        :key="`gs-opt-${index}-${optIndex}`"
                        :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                  <span
                      v-if="index > 0"
                      class="c-btn-icon delete secondary small l-item-l"
                      @click="removeGenerateStoreHandler(gs)" />
                  <div
                      v-if="index === searchParam.generateStoreIdList.length - 1 && searchParam.generateStoreIdList.length < 5"
                      class="c-btn secondary sm add l-item-l"
                      @click="addGenerateStoreHandler"
                  >
                    排出事業場を追加
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">処分業者</span>
                <Selectbox
                  class="mid"
                  :value.sync="searchParam.disposalCompanyId"
                  :validation="validateJustBlank"
                >
                  <option value="">すべて</option>
                  <option
                    v-for="(opt, index) in disposalCompanyList"
                    :key="`cba-opt-${index}`"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
              <div>
                <span class="text-label">廃棄物区分</span>
                <div class="l-flex wrap full">
                  <div
                    v-for="(wt, index) in waste_type"
                    :key="index"
                    class="c-input"
                  >
                    <div class="c-checkbox">
                      <input
                        :id="`wt-is-status-${index}`"
                        type="checkbox"
                        v-model="searchParam.wasteType"
                        :value="wt.value"
                      />
                      <label
                        class="c-checkbox__label"
                        :for="`wt-is-status-${index}`"
                      >
                        <span class="c-checkbox__box"></span>
                        {{ wt.label }}
                      </label>
                      <div v-if="wt.value == waste_type[0].value && searchParam.wasteType.includes(waste_type[0].value)">
                        <div class="l-flex wrap full">
                          <span>(</span>
                          <div
                            v-for="(jitf, index) in jwnet_item_type_field"
                            :key="index"
                            class="c-input"
                          >
                            <div class="c-checkbox">
                              <input
                                :id="`jitf-is-status-${index}`"
                                type="checkbox"
                                v-model="searchParam.jwnetItemTypeField"
                                :value="jitf.value"
                              />
                              <label
                                class="c-checkbox__label"
                                :for="`jitf-is-status-${index}`"
                              >
                                <span class="c-checkbox__box"></span>
                                {{ jitf.label }}
                              </label>
                            </div>
                          </div>
                          <span>)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">連絡番号</span>
                <InputText
                  :value.sync="searchParam.contactNo"
                  inputType="text"
                  placeholder="入力してください"
                  :validation="validateNumber"
                >
                </InputText>
              </div>
              <div>
                <span class="text-label">備考</span>
                <InputText
                  :value.sync="searchParam.remarks"
                  inputType="text"
                  placeholder="備考を入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
                >
                </InputText>
              </div>
              <div>
                <span class="text-label"></span>
                <div class="l-flex wrap full ">
                  <div class="c-input">
                    <div class="c-checkbox">
                      <input
                      :id="`isOnlyEmptyRegist-is-status`"
                      type="checkbox"
                      v-model="searchParam.isActive"
                      />
                      <label
                        class="c-checkbox__label"
                        :for="`isOnlyEmptyRegist-is-status`"
                      >
                      <span class="c-checkbox__box"></span>
                        削除は含めない
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="l-container">
      <div class="l-btns l-block-lg">
        <div class="c-btn secondary small" @click="resetSearchHandler">
          条件をリセット
        </div>
        <div class="c-btn primary small" @click="searchHandler">検索</div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/parts/input/InputText";
import InputDate from "@/components/parts/input/InputDate";
import Selectbox from "@/components/parts/input/Selectbox";

import validation from "@/mixin/validation";
import callApi from "../mixins/callApi";
import {DOMAIN} from "@/const";

export default {
  props: {
    manifestStatusCount: {
      type: Array,
    },
    isNoDateShow: {
      type: Boolean,
    },
  },
  components: {
    InputText,
    InputDate,
    Selectbox,
  },
  mixins: [validation, callApi],
  data() {
    return {
      isDisplaySearch: false,
      isSearchDetail: false,
      // 検索v-model
      searchParam: {
        wasteName: "",
        generateCompanyId: "",
        manifestNo: "",
        collectionDateFrom: "",
        collectionDateTo: "",
        generateStoreIdList: [
          {
            value: "",
          },
        ],
        disposalCompanyId: "",
        cbaItemId: "",
        jwnetItemTypeId: "",
        routeId: "",
        routeName: "",
        status: [],
        issueDateFrom: "",
        issueDateTo: "",
        wasteType: [],
        jwnetItemTypeField:[],
        contactNo: "",
        remarks: "",
        isActive: true,
      },

      // 検索内容別で持つ。ページ移動でもAPI叩くため
      searchedParam: {},
      // ページネーション用
      paginationParams: {
        page: 1,
        pageSize: 20,
        total: null,
      },
      sortParams: {
        // column: "manifest_no",
        column: "collection_date",
        type: "desc",
      },
      statusCb: [
        {
          label: "未交付",
          value: 60,
        },
        {
          label: "終了報告期限注意",
          value: 10,
        },
        {
          label: "終了報告期限切れ",
          value: 11,
        }
      ],
      waste_type: [
        { value: DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE , label: "産業廃棄物" },
        { value: DOMAIN.WASTE_TYPE.VALUABLES , label: "有価物" },
        { value: DOMAIN.WASTE_TYPE.COMMON_WASTE , label: "一般廃棄物" },
      ],
      jwnet_item_type_field: [
        { value: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_NOT_SPECIAL_INDUSTRIAL_WASTE , label: "普通産業廃棄物" },
        { value: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_SPECIAL_INDUSTRIAL_WASTE , label: "特別管理産業廃棄物" },
      ],
    };
  },

  methods: {
    showcount(statusVal) {
      let count = null;
      for (const sc of this.manifestStatusCount) {
        if (sc.manifestSearchTypeId === statusVal) {
          count = sc.count;
        }
      }
      return count;
    },

    initSearch(isReset = true) {
      this.searchParam = {
        wasteName: isReset ? "" : this.searchParam.wasteName,
        generateCompanyId: isReset ? "" : this.searchParam.generateCompanyId,
        manifestNo: isReset ? "" : this.searchParam.manifestNo,
        collectionDateFrom: isReset ? "" : this.searchParam.collectionDateFrom,
        collectionDateTo: isReset ? "" : this.searchParam.collectionDateTo,
        cbaItemId: isReset ? "" : this.searchParam.cbaItemId,
        jwnetItemTypeId: isReset ? "" : this.searchParam.jwnetItemTypeId,
        routeId: isReset ? "" : this.searchParam.routeId,
        routeName: isReset ? "" : this.searchParam.routeName,
        status: isReset ? [] : this.searchParam.status,
        issueDateFrom: "",
        issueDateTo: "",
        generateStoreIdList: [
          {
            value: "",
          },
        ],
        disposalCompanyId: "",
        wasteType: [],
        jwnetItemTypeField:[],
        contactNo: "",
        remarks: "",
        isActive: true,
      };
    },

    // 排出事業場を追加押下
    addGenerateStoreHandler() {
      this.searchParam.generateStoreIdList = [
        ...this.searchParam.generateStoreIdList,
        { value: "" },
      ];
    },

    // 排出事業場を削除押下
    removeGenerateStoreHandler(gs) {
      this.searchParam.generateStoreIdList = this.searchParam.generateStoreIdList.filter(
        (g) => g !== gs
      );
    },

    resetSearchHandler() {
      this.initSearch(true);
    },

    searchHandler() {
      this.$emit("search", this.searchParam);
    },
    
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    shorten() {
      // TODO: shortenが利用される場合、排出事業場と処分業者の検索条件を追加する処理が必要
      const result = [];
      const prev = this.$store.getters["searchParamManifestsCollectReportBefore/get"];
      if (prev.wasteName) result.push({ key: "廃棄物名称", value: prev.wasteName });
      if (prev.generateCompanyId) result.push({ key: "排出事業者", value: this.generateCompanyList.filter(c => c.id === prev.generateCompanyId)[0].name });
      if (prev.manifestNo) result.push({ key: "マニフェスト番号", value: prev.manifestNo });
      if (prev.collectionDateFrom || prev.collectionDateTo) result.push({ key: "引き渡し日", value: prev.collectionDateFrom + "〜" + prev.collectionDateTo });
      if (prev.cbaItemId) result.push({ key: "CBA品目", value: this.cbaItems.filter(c => c.id === prev.cbaItemId)[0].name });
      if (prev.jwnetItemTypeId) result.push({ key: "廃棄物種類", value: this.jwnetItemsOpt.filter(j => j.id === prev.jwnetItemTypeId)[0].name });
      if (prev.routeId) result.push({ key: "ルート番号", value: parseInt(prev.routeId)});
      if (prev.routeName) result.push({ key: "ルート名称", value: prev.routeName });
      if (prev.status.length) {
        result.push({
          key: "ステータス",
          value: this.statusCb
              .filter(scb => prev.status.includes(scb.value))
              .map(scb => scb.label).join("、")
        });
      }
      if (prev.issueDateFrom || prev.issueDateTo) result.push({ key: "交付日", value: prev.collectionDateFrom + "〜" + prev.collectionDateTo });
      if (prev.wasteType) {
        result.push({ 
          key: "廃棄物区分", 
          value: this.waste_type
              .filter(scb => prev.wasteType .includes(scb.value))
              .map(scb => scb.label).join("、")
          });
      }
      if (prev.jwnetItemTypeField) result.push({ key: "廃棄物種類区分", value: prev.jwnetItemTypeField });
      if (prev.contactNo) result.push({ key: "連絡番号", value: prev.contactNo });
      if (prev.remarks) result.push({ key: "備考", value: prev.remarks });
      if (prev.isActive) result.push({ key: "削除を含めない", value: prev.isActive });

      return result;
    },

  },

  mounted() {
    this.searchParam = this.$store.getters["searchParamManifestsCollectReportBefore/get"];
    this.isSearchDetail = this.$store.getters["searchParamManifestsCollectReportBefore/isSearchDetail"];
    this.isNoDateShow = this.$store.getters["searchParamManifestsCollectReportBefore/isNoDateShow"];
 
    this.getStoreListApi();
    this.getGeneratorApi();
    this.getJwnetItemsApi();
    this.getGeneratorDisposalApi();
    this.getCbaItemsApi();
  },
};
</script>
