<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="終了報告前マニフェスト" />
    <AppSideMenu />
    <main class="l-main">
      <ToastMessage :toastMsg.sync="toastMsg" />
      <div class="l-container">
        <div class="c-tabs lg l-area">
          <div
            class="c-tabs__each"
            :class="{
              active: !isNoDateShow,
            }"
            @click="switchTab"
          >
            終了報告待ち
          </div>
          <div
            class="c-tabs__each"
            :class="{
              active: isNoDateShow,
            }"
            @click="switchTab"
          >
            引き渡し日未設定
          </div>
        </div>
      </div>

      <!-- 検索フォーム ============================================================ -->
      <SearchForm
        ref="searchForm"
        :manifestStatusCount="manifestStatusCount"
        :isNoDateShow="isNoDateShow"
        @search="getManifestSearchApi"
      />

      <!-- 検索結果 ============================================================ -->
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div v-if="manifestList.length" class="l-flex cols l-item" :class="{ disabled: isCallApiProcess }">
          <div class="u-txt-bold">選択中 {{ checkCount }} 件</div>
          <div class="l-flex cols" :class="{ disabled: !checkCount }">
            <div class="c-btn primary small" @click="showChecksheetDownloadModal">受渡確認票印刷</div>
          </div>
          <div class="c-btn secondary small upload" style="margin-left: auto;!important;width: 16rem;" @click="onClickCsvUploadModalDisplayButton">CSVアップロード</div>
          <div class="c-btn secondary small download" style="width: 16rem;" @click="$refs.csvdownloadmodal.updateCSVModal(true)">CSVダウンロード</div>
        </div>

        <div class="c-infoList__wrap">
          <div class="c-infoList scroll l-box">
            <div class="c-infoList__row head">
              <div class="c-infoList__cell cb">
                <div class="c-checkbox">
                  <input
                    id="check-all-manifests"
                    type="checkbox"
                    v-model="isAllChecked"
                    @change="changeAllCheckHandler"
                  />
                  <label class="c-checkbox__label" for="check-all-manifests">
                    <span class="c-checkbox__box"></span>
                  </label>
                </div>
              </div>
              <div class="c-infoList__cell sm">マニフェスト番号</div>
              <div class="c-infoList__cell sm">ステータス</div>
              <div v-if="!isNoDateShow" class="c-infoList__cell full-date">
                <a @click="clickSortChangeHandler">
                    <span
                        class="c-infoList__cell__sort"
                        :class="{
                        asc: sortParams.type.toLowerCase() === 'asc',
                        desc: sortParams.type.toLowerCase() === 'desc',
                      }"
                    >引き渡し日</span>
                </a>
              </div>
              <div v-if="!isNoDateShow" class="c-infoList__cell sm">
                交付日
              </div>
              <div class="c-infoList__cell sm">廃棄物名称</div>
              <div class="c-infoList__cell sm">詳細名称</div>
              <div class="c-infoList__cell sm">数量</div>
              <div class="c-infoList__cell sm">排出業者</div>
              <div class="c-infoList__cell sm">排出事業場</div>
              <div class="c-infoList__cell md">運搬先</div>
            </div>

            <!-- 検索結果あり -->
            <template v-if="manifestList.length">
              <div
                v-for="manifest of manifestList"
                :key="manifest.manifestNo"
                :to="`/generator/contract/${manifest.id}`"
                class="c-infoList__row list"
              >
                <div class="c-infoList__cell cb text-dotted">
                  <div class="c-checkbox">
                    <input
                      :id="manifest.manifestId"
                      type="checkbox"
                      v-model="manifest.isChecked"
                      @change="changeManifestCbHandler"
                    />
                    <label class="c-checkbox__label" :for="manifest.manifestId">
                      <span class="c-checkbox__box"></span>
                    </label>
                  </div>
                </div>
                <div @click="toDetail(manifest.manifestId)" class="c-infoList__cell sm text-dotted">
                  <router-link :to="`/manifest/${manifest.manifestId}`">
                    {{ manifest.manifestNo | blankText }}
                  </router-link>
                </div>
                <!-- ステータス -->
                <div @click="toDetail(manifest.manifestId)" class="c-infoList__cell sm text-dotted">
                  {{ manifest.manifestStatusType == null ? "": manifest.manifestStatusType.name }}
                </div>
                <!-- 引き渡し日 -->
                <div v-if="!isNoDateShow" class="c-infoList__cell full-date c-tooltip" @click="toDetail(manifest.manifestId)">
                  <div class="c-infoText">
                    <div>
                      <template v-if="manifest.isEdit">
                        <InputDate class="inline-edit-mode"
                            :value.sync="manifest.editCollectionDate"
                            placeholder="0000/00/00"
                            :validation="validateJustBlank"
                            :isValid.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editCollectionDate.isValid"
                            :errMsg.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editCollectionDate.errMsg"
                        />
                      </template>
                      <template v-else>
                        {{ manifest.collectionDate | dateJpMDddFormat }}
                      </template>
                    </div>
                  </div>
                  <div v-if="manifest.collectionDateLimit" class="c-status" :class="manifest.collectionDateLimit">
                    <div class="c-infoList__popup">
                      <span v-if="manifest.collectionDateLimit === limitStatus.INFO">交付時は入力が必要です<br></span>
                      <span v-if="manifest.collectionDateLimit === limitStatus.WARNING">交付期限が迫っています<br></span>
                      <span v-if="manifest.collectionDateLimit === limitStatus.DANGER">交付期限を超過しています<br></span>
                    </div>
                  </div>
                </div>

                <!-- 交付日 -->
                <div v-if="!isNoDateShow" class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
                  {{ manifest.issueDate | dateJpMDddFormat }}
                </div>

                <div class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
                  {{ manifest.wasteName === null ? "" : manifest.wasteName.name }}
                </div>
                <div class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
                  {{ manifest.detailWasteName === null ? "" : manifest.detailWasteName }}
                </div>
                <div class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
                  {{ (manifest.wasteQuantity === null ? "" : manifest.wasteQuantity) + (manifest.wasteUnitType === null ? "" : manifest.wasteUnitType.name) }}
                </div>
                <div class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
                  {{ manifest.generateCompany === null ? "" : manifest.generateCompany.name }}
                </div>
                <div class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
                  {{ manifest.generateStore === null ? "" : manifest.generateStore.name }}
                </div>
                <div class="c-infoList__cell md text-dotted" @click="toDetail(manifest.manifestId)">
                  {{ manifest.destinationCompanyName === null ? "" : manifest.destinationCompanyName }}
                </div>

                <!-- 終了報告ボタンを押して終了報告画面に遷移 -->
                <div v-if="manifest.isAbleCollectionReport && !isNoDateShow && manifest.collectCompany[0].id === collectCompanyId" class="c-infoList__cell sm sticky" @click="toCollectionReport(manifest.manifestId)">
                  <div>
                      <div class="c-btn secondary sm">終了報告</div>
                  </div>
                </div>
              </div>
            </template>

            <!-- 検索結果なし -->
            <template v-else>
              <div class="c-infoList__row list">
                <div class="c-infoList__cell">
                  検索条件に一致するマニフェストはありません。
                </div>
              </div>
            </template>
          </div>
        </div>

        <Pagination v-if="manifestList.length"
            :params="paginationParams"
            :page.sync="paginationParams.page"
            @callback="getManifestSearchApi(searchedParam, true)"
        />
      </div>
    </main>

    <!-- 登録モーダル -->
    <div class="c-modal lg" v-show="isChecksheetDownloadModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">

        <div class="c-modal__head">
          <h5 class="c-lead-s">受渡確認票印刷ダウンロード</h5>
          <span class="c-modal__head__close" @click="closeModalHandler"></span>
        </div>
        <div class="c-modal__body">
          <p class="u-txt-center">受け渡し確認票をダウンロードする形式を選択してください</p>
          <p class="u-txt-center" v-if="checksheetDownloadMessage">{{ checksheetDownloadMessage }}</p>

          <div
          v-for="cdt in checksheetDownloadTypes"
          :key="cdt.id"
          class="c-modal__foot"
          >
            <div>
              <div class="c-btn primary download-sm" :class="{ disabled: isDisableChecksheetDownload }" @click="checksheetDownloadHandler(cdt.value)">{{ cdt.label }}</div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div class="c-btn secondary small" @click="closeModalHandler">閉じる</div>
        </div>
      </div>
    </div>
    <AppFooter />

    <ManifestCsvUploadModal v-if="isDisplayCsvUploadModal" 
      v-bind:onClickCloseButton="hideCsvUploadModal"
      v-bind:onClickUploadButton="onClickUploadCsv"
      v-bind:onClickUploadRegisterCsvButton="onClickUploadRegisterCsv"
    />

    <CSVDownloadModal
      ref="csvdownloadmodal"
      @downloadCSVHandler="downloadCSVHandler"
    />

  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import SearchForm from "./components/SearchForm.vue";
import search from "./mixins/search";
import Pagination from "@/components/parts/Pagination";
import { API_URL, DOMAIN} from "@/const";
import { saveAs } from "file-saver";
import {DATE_LIMIT_STATUS} from "@/const/dateLimit";
import ToastMessage from "@/components/parts/ToastMessage";
import manifestCallApi from "../../mixins/callApi";
import ManifestCsvUploadModal from "../../components/ManifestCsvUploadModal";
import CSVDownloadModal from "../../components/CSVDownloadModal";

export default {
  name: "manifest-collect-report-before",
  components: {
    SearchForm,
    Pagination,
    ToastMessage,
    ManifestCsvUploadModal,
    CSVDownloadModal,
  },
  mixins: [search, manifestCallApi],
  data() {
    return {
      isNoDateShow: false,
      isSearchDetail: false,
      isAllChecked: false,
      checkCount: 0,
      manifestStatusCount: [],
      manifestList: [],
      // 検索内容別で持つ。ページ移動でもAPI叩くため
      searchedParam: {},
      // ページネーション用
      paginationParams: {
        page: 1,
        pageSize: 10,
        total: null,
      },
      sortParams: {
        column: "collection_date",
        type: "desc",
      },
      errMsgs: [],
      toastMsg: "",
      limitStatus: DATE_LIMIT_STATUS,
      isDisplayCsvUploadModal: false,
      isChecksheetDownloadModalShow: false,
      checksheetDownloadTypes: [
        {
          label: "PDFダウンロード(複数一括)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.BUNDLE_PDF,
        },
        {
          label: "PDFダウンロード(単票)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_ONE_SHEET,
        },
        {
          label: "PDFダウンロード(連票)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_THREE_SHEET,
        },
        {
          label: "EXCELダウンロード",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.EXCEL_ZIP,
        },
      ],
      isDisableChecksheetDownload: false,
      checksheetDownloadMessage: "",
      isCallApiProcess: false,
      collectCompanyId: this.$store.getters["userInfo/get"].groupInfo.id,
    };
  },
  methods: {
    closeModalHandler() {
      this.isChecksheetDownloadModalShow = false;
    },

    // 受渡確認票印刷押下
    showChecksheetDownloadModal() {
      this.isChecksheetDownloadModalShow = true;
    },

    // 受渡確認票をダウンロード
    checksheetDownloadHandler(downloadTypeId) {
      this.errMsgs = [];

      this.isDisableChecksheetDownload = true;
      this.checksheetDownloadMessage = `${this.checksheetDownloadTypes.find(t => t.value === downloadTypeId).label}を行なっています。少々お待ちください。`;
      axios
        .post(API_URL.MANIFEST.DELIVERY_CHECKSHEET
            , { downLoadTypeId: downloadTypeId, manifestIdList: this.getCheckedManifestIdList() }
            , { responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          if (downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_ONE_SHEET) {
            saveAs(blob, `受渡確認表(単票)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else if(downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_THREE_SHEET) {
            saveAs(blob, `受渡確認表(連票)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else if(downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.BUNDLE_PDF) {
            saveAs(blob, `PDFダウンロード(複数一括)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else {
            saveAs(blob, `受渡確認表_${moment().format("YYYYMMDDHHmmss")}.zip`);
          }
        })
        .then(() => {
          // ダウンロードボタンは活生にする
          this.isDisableChecksheetDownload = false;
          this.checksheetDownloadMessage = "";
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });      
    },

    // 終了報告画面に遷移する
    toCollectionReport(manifestId) {
      const transitionSource = location.pathname;
      this.$router.push(`/manifest/collect/report/${manifestId}?transitionSource=${transitionSource}`);
    },

    // 詳細画面に遷移する
    toDetail(manifestId) {
      const transitionSource = location.pathname;
      this.$router.push(`/manifest/${manifestId}?transitionSource=${transitionSource}`);
    },

    /**
     * CSVフォーマット選択モーダル内ダウンロードボタン押下時
     */
    downloadCSVHandler(csvformatId) {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      axios
          .post(API_URL.MANIFEST.COLLECT_CSV_DOWNLOAD_BY_FORMAT
              , {
                manifestSearchFileDownloadInfoForCollector: this.getCSVDownloadSearchParamForCollector(),
                csvformatId: csvformatId
              }
              , { responseType: "blob" }
          )
          .then((res) => {
            const blob = new Blob([res.data], {
              type: res.data.type
            });
            saveAs(blob, `マニフェスト検索結果_${moment().format("YYYYMMDDHHmmss")}.csv`);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
          })
          .catch((err) => {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
            this.$refs.csvdownloadmodal.updateCSVModal(false);
          });
    },

    // 運搬用CSVダウンロード用の検索条件パラメーター
    getCSVDownloadSearchParamForCollector() {
      let generateStoreIdList = [];
      if (this.searchedParam.generateStoreIdList) {
        this.searchedParam.generateStoreIdList.forEach (
            generateStoreId => {
              if (generateStoreId.value !== "") {
                generateStoreIdList.push(generateStoreId.value);
              }
            }
        )
      }
      return {
        searchConditionId: this.isNoDateShow ? 51 : 50,
        wasteName: this.searchedParam.wasteName === "" ? null : this.searchedParam.wasteName,
        generateCompanyId: this.searchedParam.generateCompanyId === "" ? null : this.searchedParam.generateCompanyId,
        manifestNo: this.searchedParam.manifestNo === "" ? null : this.searchedParam.manifestNo,
        collectionDateFrom: this.searchedParam.collectionDateFrom === "" ? null : this.searchedParam.collectionDateFrom,
        collectionDateTo: this.searchedParam.collectionDateTo === "" ? null : this.searchedParam.collectionDateTo,
        cbaItemId: this.searchedParam.cbaItemId === "" ? null : this.searchedParam.cbaItemId,
        jwnetItemTypeId: this.searchedParam.jwnetItemTypeId === "" ? null : this.searchedParam.jwnetItemTypeId,
        routeId: this.searchedParam.routeId === "" ? null : this.searchedParam.routeId,
        routeName: this.searchedParam.routeName === "" ? null : this.searchedParam.routeName,
        collectManifestSearchTypeIdList: this.searchedParam.status,
        generateStoreId: generateStoreIdList,
        disposalCompanyId: this.searchedParam.disposalCompanyId === "" ? null : this.searchedParam.disposalCompanyId,
        wasteTypeList: this.searchedParam.wasteType,
        jwnetItemTypeFieldList: this.searchedParam.jwnetItemTypeField,
        contactNo: this.searchedParam.contactNo === "" ? null : this.searchedParam.contactNo,
        remarks: this.searchedParam.remarks === "" ? null : this.searchedParam.remarks,
        isActive: this.searchedParam.isActive === false ? null : this.searchedParam.isActive,
        issueDateFrom: this.searchedParam.issueDateFrom === "" ? null : this.searchedParam.issueDateFrom,
        issueDateTo: this.searchedParam.issueDateTo === "" ? null : this.searchedParam.issueDateTo,
        collectionReportDateFrom: this.searchedParam.collectionReportDateFrom === "" ? null : this.searchedParam.collectionReportDateFrom,
        collectionReportDateTo: this.searchedParam.collectionReportDateTo === "" ? null : this.searchedParam.collectionReportDateTo,
        sort: this.sortParams.column + ":" + this.sortParams.type
      };
    },

    // 廃棄物名区分を表示する: 2次フェース向け_廃棄物種類情報待ち
    getJwnetItemManifestType(jwnetItemType, manifestType) {
      let join = (jwnetItemType !== null && manifestType !== null) ? "/" : "";
      let jwnetItemTypeName = (jwnetItemType === null) ? "": jwnetItemType.name;
      if(manifestType === null) return null;
      let manifestTypeName;
      if (manifestType === null) {
        manifestTypeName = "";
      } else if (manifestType.id === DOMAIN.MANIFEST_TYPE.DIGITAL)
      if(manifestType.id === DOMAIN.MANIFEST_TYPE.DIGITAL) {
        manifestTypeName = "電子"
      } else {
        manifestTypeName = "紙"
      }
      return jwnetItemTypeName + join + manifestTypeName
    },

    // マニフェスト件数取得Api
    getManifestCountApi() {
      const searchConditionId = this.isNoDateShow ?
        String(DOMAIN.MANIFEST_SEARCH_CONDITION.BEFORE_REPORT_NODATE) : String(DOMAIN.MANIFEST_SEARCH_CONDITION.BEFORE_REPORT);
      axios
        .get(API_URL.MANIFEST.SUMMARY_COLLECT + searchConditionId)
        .then((res) => {
          this.manifestStatusCount = res.data.manifestsSummaryList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 引き渡し日入力済み/未入力の切り替え
    switchTab() {
      this.isNoDateShow = !this.isNoDateShow;
      // 検索条件もリセットして、リスト取得し直す
      this.isSearchDetail = false;
      this.initSearch();
      this.getManifestSearchApi(this.$store.getters["searchParamManifestsCollectReportBefore/get"], false);
      this.$store.commit("searchParamManifestsCollectReportBefore/setIsNoCollectionDate", this.isNoDateShow);
      this.$emit("search", this.searchParam);
    },

    initSearch() {
      this.searchParam = {
        wasteName: "",
        generateCompanyId: "",
        collectionDateFrom: "",
        collectionDateTo: "",
        issueDateFrom: "",
        issueDateTo: "",
        status: [],
        disposalCompanyId: "",
        manifestNo: "",
        contactNo: "",
        jwnetItemTypeId: "",
        cbaItemId: "",
      };
    },

    // マニフェスト全件チェックボックス
    changeAllCheckHandler() {
      if (this.isAllChecked) {
        this.manifestList.map((manifest) => {
          manifest.isChecked = true;
        });
      } else {
        this.manifestList.map((manifest) => {
          manifest.isChecked = false;
        });
      }
    },

    // 期限ステータスチェック
    checkLimitStatus(date, issueAlertType) {
      if (!date) return DATE_LIMIT_STATUS.INFO;
      if (issueAlertType === 2) {
        return DATE_LIMIT_STATUS.DANGER;
      } else if (issueAlertType === 1) {
        return DATE_LIMIT_STATUS.WARNING;
      } else return DATE_LIMIT_STATUS.NOT_YET;
    },

    // 各マニフェストのチェックボックス
    changeManifestCbHandler(isChecked) {
      if (!isChecked) {
        this.isAllChecked = false;
      }
    },

    // 引き渡し日ソート変更
    clickSortChangeHandler() {
      this.sortParams.type = this.sortParams.type.toLowerCase() === "asc" ? "desc" : "asc";
      this.getManifestSearchApi(this.searchedParam, false);
    },

    getCheckedManifestIdList() {
      const checkedManifests = this.manifestList.filter(
        (manifest) => manifest.isChecked
      );
      const manifestIdList = checkedManifests.map((manifest) => {
        return { manifestId: manifest.manifestId };
      });
      return manifestIdList;
    },

    // 受渡確認票印刷押下
    clickPrintHandler() {
      this.errMsgs = [];
      axios
        .post(API_URL.MANIFEST.DELIVERY_CHECKSHEET
            , { manifestIdList: this.getCheckedManifestIdList() }
            , { responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          saveAs(blob, `受渡確認表_${moment().format("YYYYMMDDHHmmss")}.zip`);
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });
    },
    hideCsvUploadModal() {
      this.isDisplayCsvUploadModal = false;
    },
    onClickCsvUploadModalDisplayButton() {
      this.isDisplayCsvUploadModal = true;
    },

    onClickUploadCsv(csvFile) {
      return this.postManifestCsvupload(csvFile)
        .then(this.onUpload);
    },
    onClickUploadRegisterCsv(csvFile) {
      return this.postManifestCsvregister(csvFile)
        .then(this.onUpload);
    },

    onUpload() {
      this.hideCsvUploadModal();
      this.toastMsg = "正常にアップロードしました。";
      // 再検索を行う
      this.getManifestSearchApi(this.$store.getters["searchParamManifestsCollectReportBefore/get"], false);
    }
  },

  watch: {
    manifestList: {
      handler: function () {
        this.manifestList.forEach(manifest => {
          manifest.collectionDateLimit = this.checkLimitStatus(manifest.collectionDate, manifest.issueAlertType);
          manifest.isAbleCollectionReport= (manifest.manifestStatusType.id === DOMAIN.MANIFEST_STATUS.ISSUED && manifest.collectionReportDate === null);
        });

        this.checkCount = this.manifestList.filter(m => m.isChecked).length;
      },
      deep: true,
    },
  },

  beforeCreate() {
    // クエリパラメータのチェック
    if (this.$route.query.status || this.$route.query.clear) {
      this.$store.commit("searchParamManifestsCollectReportBefore/set", {
        wasteName: "",
        generateCompanyId: "",
        manifestNo: "",
        collectionDateFrom: "",
        collectionDateTo: this.$route.query.collectionDateTo ? this.$route.query.collectionDateTo : "",
        cbaItemId: "",
        jwnetItemTypeId: "",
        routeId: "",
        routeName: "",
        status: this.$route.query.status ? this.$route.query.status.split(",").map(s => Number(s)) : [],
        issueDateFrom: "",
        issueDateTo: "",
        wasteType: [],
        jwnetItemTypeField:[],
        contactNo: "",
        remarks: "",
        isActive: true,
      });
      // 終了報告前の引き渡し日未設定画面から：詳細画面の戻るボタンの遷移用
      if (this.$route.query.isNoDateShow) {
        this.isNoDateShow = this.$route.query.isNoDateShow;
        this.$store.commit("searchParamManifestsCollectReportBefore/setIsNoCollectionDate", this.$route.query.isNoDateShow);
      }
    }
  },

  mounted() {
    this.getManifestCountApi();
    this.getManifestSearchApi(this.$store.getters["searchParamManifestsCollectReportBefore/get"], false);
  },
};
</script>
