export const REPORT_DEADLINE = {
  COLLECT_REPORT_INTERVAL: 90, // 運搬報告期限
  DISPOSAL_REPORT_INTERVAL: 90, // 処分報告期限
  FINAL_DISPOSAL_REPORT_INTERVAL: 180, // 最終処分報告期限(特別産業廃棄物でも同じく)
  SPECIAL_WASTE_REPORT_INTERVAL: 60, // 特別産業廃棄物の運搬・処分
  WARNING_ALERT_INTERVAL: 30 // 黄色の注意アラートはそれぞれ30日前から表示
};

export const DATE_LIMIT_STATUS = {
  NOT_YET: "",
  WARNING: "warning", // 期限注意
  DANGER: "danger", // 期限切れ
  INFO: "info", // インフォメーション
};

export const ALERT_TYPE = {
  NOTHING: 0, // アラートなし
  ATTENTION: 1, // 期限注意アラート
  WARNING: 2, // 期限切れアラート
};

export const CONTRACT_END_ALERT_INTERVAL = 30; // 契約終了日までのアラート期間
export const LICENCE_EXPIRED = 30; // 許可証終了日までのアラート期間